body {
  font-family: var(--font-gabarito), sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-comfortaa), sans-serif;
}


.comfortaa-font {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
